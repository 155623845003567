require('dotenv').config();

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.red";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey="__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;


/*
APP API
*/
export const APIConfig = {
  APIPath: process.env.REACT_APP_APIPATH,
  APIAuthKey: process.env.REACT_APP_APIAUTHKEY
}
export const ServiceApiV3 = process.env.REACT_APP_APIPATH;
export const CamEdMobileApi = process.env.REACT_APP_APIPATH_MOBILE;
export const GoogleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const SecretKey = "borin168!@#-secretkey"; //process.env.SECRET_KEY;



/*
ACCESS MESSAGE
*/
export const Unauthorized = "Your're not authorized to access! Please login again to continue.";
export const Offline = "Your're offline, check your connnection!";
export const AccessError = "Access Error";
export const ConnectionError = "Connection Error";
export const RequestSuccessMsg = "You've successfully requested! Please make payment before your document is issued!";

/*
IMAGEs & DOCs ON CLOUD
*/
export const NoImage ='https://imagedelivery.net/22Use1D1z0CeXnTc4TRU3A/e264ef8f-4d60-47ee-204d-141ff7bba700/thumbnail';
export const StudentDocs = 'https://imagedelivery.net/22Use1D1z0CeXnTc4TRU3A/';
