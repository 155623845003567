import { all, call, delay, fork, put, takeEvery } from 'redux-saga/effects';
import { requestAPI, requestAPIInHeader, requestAPIInHeaderwithBody } from '../../helpers/HttpRequest';
import {
    LOGIN_USER,
    LOGIN_USER_WITH_GOOGLE,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    UPDATE_USER_PROFILE,
    GET_DATA_REQUEST,
    REQUEST_SCORE_REVIEW,
} from '../actions';

import {
    loginUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    logoutUserSuccess,
    getDataSuccess,
    getDataError,
    getRequestSuccess,
    getRequestFail
    
} from './actions';

import { EncryptStorage } from 'encrypt-storage';

//////////LOGIN
export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithIDPassword);
}

const loginWithIDPasswordAsync = async (method, data) =>
    await requestAPI(method, data)

function* loginWithIDPassword({ payload }) {
    const { method } = {method:'POST'};
    const data = { 
                    url: "login/login",
                    body: payload.user
                };

    const { history } = payload;

    try {
        const loginUser = yield call(loginWithIDPasswordAsync, method, data);

        if (loginUser.data.status !== 204) {
            localStorage.setItem('user_id', loginUser.data.studentid);
            localStorage.setItem('authkey', loginUser.data.token);
            localStorage.setItem('lastlogin', loginUser.data.lastlogin);
            localStorage.setItem('activeterm', loginUser.data.activeterm);
            history.push('/');
        } else {
            yield put(loginUserError(loginUser.data.message));
        }
    } catch (error) {
        yield put(loginUserError('Please check your connection'));

    }
}
///////END LOGIN


//////////LOGIN WITH GOOGLE
export function* watchLoginUserwithGoogle() {
    yield takeEvery(LOGIN_USER_WITH_GOOGLE, loginWithGooglePassword);
}

const loginWithGooglePasswordAsync = async (method, data) =>
    await requestAPI(method, data)

function* loginWithGooglePassword({ payload }) {
    const { method } = {method:'POST'};
    const data = 
        { url: "login/gmailsignin",
          body: payload.user
    };
    const { history } = payload;
    
    try {
        const loginUserWithGoogle = yield call(loginWithGooglePasswordAsync, method, data);
        if (loginUserWithGoogle.data.status !== 204) {
            localStorage.setItem('user_id', loginUserWithGoogle.data.studentid);
            localStorage.setItem('authkey', loginUserWithGoogle.data.token);
            history.push('/');

        } else {
            yield put(loginUserError(loginUserWithGoogle.data.message));
        }
    } catch (error) {
        yield put(loginUserError('Please check your connection'));

    }
}
///////END LOGIN GOOGLE


///////LOGOUT
export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logoutUser);
}

const logoutAsync = async (method, data) =>
    await requestAPIInHeader (method, data)

function* logoutUser({ payload }) {
    const { method } = {method:'POST'};
    const data = { url: "login/logout", 
                   studentid: localStorage.getItem('user_id'), 
                   authkey: localStorage.getItem('authkey')
                };
    const { history } = payload;
   
    try {

        yield call(logoutAsync, method, data);
        history.push('/user/login');
        yield put(logoutUserSuccess("success"));
        localStorage.clear();
        window.location.reload(true);

    } catch (error) {  }
}
///////END LOGOUT

///////GET STUDETNPROFILE
function* watchGetStudentProfile() {
    yield takeEvery(GET_DATA_REQUEST, getStudentProfile);
}

const GetStudentProfileAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

// Create a generator function
function* getStudentProfile( { payload } ) {

    const { method } = {method:'GET'};
    const data = {url: "student/studentprofile", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey')
                };
    const { history } = payload;

    try {
        const UserProfile = yield call(GetStudentProfileAsync, method, data);
        
        if (UserProfile.status == 401) {
            ////CHECK AUTHRIZATION
            yield put(loginUserError(UserProfile.message));
            localStorage.clear();
            history.push('/user/login');

        }else{
            const responesarr = UserProfile[0];           
            yield put(getDataSuccess( { data: responesarr } ));            
        }

    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}

///////END STUDENTPROFILE


/* 
**  [UPDATE STUDENTPROFILE]
**  AUTHURIZATION: StudentID / Authorization
**  BODY: PHONE,EMAIL
**  API: requestAPIInHeaderwithBody()
*/
export function* watchUpdateStudentProfile() {
    yield takeEvery(UPDATE_USER_PROFILE, updateStudentProfile);
}

const UpdateProfileAsync = async (method, data) =>
    await requestAPIInHeaderwithBody(method, data)

function* updateStudentProfile({ payload }) {
 
    const { method } = {method:'POST'};
    const data = { url: "student/studentprofile",
                   studentid: localStorage.getItem('user_id'),
                   authkey: localStorage.getItem('authkey'),
                   body: payload.user
            };
    const { history } = payload;
   
    try {
        const onUpdateProfile = yield call(UpdateProfileAsync, method, data);
        //console.log(onUpdateProfile.data);
        if (onUpdateProfile.data.result === 1) {
            yield put(getDataSuccess("success"));
            history.push('app/pages/profile/editprofile');
        } else {
            yield put(loginUserError(onUpdateProfile.data.message));
            alert('Update Error');
        }
    } catch (error) {
        yield put(loginUserError('Please check your connection'));

    }
}
///////END UPDATE STUDENTPROFILE


/* 
**  FORGET PASSWORD
**  NO Auth,Studentid in header
**  BODY: StudentID
**  API: requestAPI()
*/
export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgetPasswordAsync = async (method, data) =>
    await requestAPI(method, data)

function* forgotPassword({ payload }) {
    const { method } = {method:'POST'};
    const data = 
        { url: "login/resetpasswd",
          body: payload.forgotUserMail
    };

    const { history } = payload;
    try {
        const forgotPassword = yield call(forgetPasswordAsync, method, data);
        
        if (forgotPassword.data.status !== 401) {
            localStorage.setItem('message',forgotPassword.data.message)
            yield put(forgotPasswordSuccess("success"));
            history.push('/user/login');
            
        } else {
            yield put(forgotPasswordError(forgotPassword.data.message));
        }
    } catch (error) {
        yield put(forgotPasswordError('Please check your connection'));
        history.push('/user/login');
        localStorage.clear();

    }
}
///////END FORGET PASSWORD


/* 
**  [RESET PASSWORD]
**  AUTHURIZATION: StudentID / Authorization
**  BODY: StudentID
**  API: requestAPIInHeaderwithBody()
*/
export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (method, data) =>
    await requestAPIInHeaderwithBody(method, data)

function* resetPassword({ payload }) {
    const { method } = {method:'POST'};
    const data = { url: "student/changepasswd",
                   studentid: localStorage.getItem('user_id'),
                   authkey: localStorage.getItem('authkey'),
                   body: payload
                };
    const { history } = payload;
    
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, method, data);

        if (resetPasswordStatus.status !== 401) {
                if (resetPasswordStatus.data.status ===200){ //CHECK IF CHANGE SUCCESS
                    yield put(resetPasswordSuccess("success"));
                    history.push('/user/login');
                    localStorage.clear();   
                }else{
                    yield put(resetPasswordError("Your Current Password Does not Match"));
                }
                          
            } else { //ACCESS Unauthorized
                yield put(resetPasswordError(resetPasswordStatus.message+' Access Please login again!'));
                history.push('/user/login');
            }

    } catch (error) { ///REQUEST FRM API FAIL
        yield put(resetPasswordError(error));

    }
}
///////END RESET PASSWORD


///////GET UPCOMINGCLASS
/*function* watchGetUpcomingclass() {
    yield takeEvery(GET_DATA_REQUEST, getUpcomingClasss);
}

const GetUpcomingClassAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

// Create a generator function
function* getUpcomingClasss( action ) {

    const { method } = {method:'GET'};
    const data = {url: "student/upcomingclass", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey')
                };
    const { history } = action.payload;
    
    try {
        const UpcomingClass = yield call(GetUpcomingClassAsync, method, data);
            const responesarr = UpcomingClass;
            yield put(getUpcomingClass( { data: responesarr } ));
          
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}*/

///////GET STUDYSCHEDULE
/*function* watchGetStudySchedlue() {
    yield takeEvery(GET_DATA_REQUEST, getstudyschedule);
}

const GetStudySchedlueAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

// Create a generator function
function* getstudyschedule( action ) {

    const { method } = {method:'POST'};
    const data = {url: "student/studentstudyschedule", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey')
                };
    const { history } = action.payload;
    
    try {
        const response = yield call(GetStudySchedlueAsync, method, data);
           
        //console.log(response);
            // if (response.status !== 401) {
            //    yield put(getStudySchedule( { data: response } )); 
            // }else{
            //     yield put(getDataError({message: response.message}));
            //     alert('Error');
            //     history.push('/user/login');
            //     localStorage.clear();
            // }

            // const array_schedule=[];           
           
            // response['data'].map((data, i) => {
            //     data['schedule'].map((item, i) => {
                   
            //         array_schedule.push({
            //             class: item['class'],
            //             date: item['date'],
            //             scdate: item['scdate'],
            //             sessionam: item['sessionnam'],
            //             roomnam: item['roomname']
                      
            //         });
            //      });
            // });

        yield put(getStudySchedule( { data: response } ));
          
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}
///////END STUDYSCHEDULE

///////GET ENROLLMENT
function* watchGetEnrollment() {
    yield takeEvery(GET_DATA_REQUEST, getenrollment);
}

const GetEnrollmentAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

// Create a generator function
function* getenrollment( action ) {

    const { method } = {method:'POST'};
    const data = {url: "student/studentenrollment", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey')
                };
    const { history } = action.payload;
    
    try {
        const response = yield call(GetEnrollmentAsync, method, data);
        yield put(getEnrollment( { data: response } ));
          
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}*/

/**** STUDENT SCORE ****
1. student/studentclass[param]
    'StudentID' : stuId,
    'Authorization' : authKey

2. student/classscoretype[param]
    'StudentID' : stuId,
    'Authorization' : authKey,
    'teacher_to_class_id' : teacher_to_class_id

3.student/scoredetail[param]
    'StudentID' : data.stuId,
    'Authorization' : data.authKey,
    'teacher_to_class_id' : scoreInfo.teacher_to_class_id,
    'scoretype' : scoreID
 ************************/

/*function* watchGetStudentClass() {
    yield takeEvery(GET_DATA_REQUEST, getstudentclass);
}

const GetStudentClassAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

// Create a generator function
function* getstudentclass( action ) {

    const { method } = {method:'POST'};
    const data = {url: "student/studentclassscore", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey')
                };
    const { history } = action.payload;
    
    try {
        const response = yield call(GetStudentClassAsync, method, data);
        yield put(getStuClass( { data: response } ));
          
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}

// function* watchGetScoreType() {
//     yield takeEvery(TYPES.GET_SCORE_TYPE, getscoretype);
// }

// const GetScoreTypeAsync = async (method, data) =>
//     await requestAPITeahcer2ClassID(method, data)

// // Create a generator function
// function* getscoretype( payload ) {

//     const { method } = {method:'GET'};
//     const t2classid = payload['payload']['teacher_to_class_id'];
//     const data = {url: "student/classscoretype", 
//                   studentid: localStorage.getItem('user_id'), 
//                   authkey: localStorage.getItem('authkey'),
//                   teacher_to_class_id: t2classid
//                 };

//     ///const { history } = payload['payload']['history'];

//     try {
//         const response = yield call(GetScoreTypeAsync, method, data);
//         yield put(getScoreTypeSuccess( { data: response } ));
        
          
//     }catch(error) { 
//         yield put(getDataError(error));
//         yield delay(500);
//         //history.push('/user/login');
//         localStorage.clear();

//     }
// }
///////END STUDENT SCORE

/////// STUDENT ATTENDANT LOG
function* watchGetAttendantLog() {
    yield takeEvery(GET_DATA_REQUEST, getattendantlog);
}

const GetAttLogClassAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

// Create a generator function
function* getattendantlog( action ) {

    const { method } = {method:'GET'};
    const data = {url: "student/scannlog", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey'),
                  body: ''
                };
    const { history } = action.payload;
    
    try {
        const response = yield call(GetAttLogClassAsync, method, data);
   
        yield put(getAttLog( { data: response } ));
        
          
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}

/////// STUDENT ATTENDANT REPORT
function* watchGetAttendantReport() {
    yield takeEvery(GET_DATA_REQUEST, getattendantreport);
}

const GetAttReportClassAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

// Create a generator function
function* getattendantreport( action ) {

    const { method } = {method:'POST'};
    const data = {url: "student/studentattendance", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey'),
                  body: ''
                };
    const { history } = action.payload;
    
    try {
        const response = yield call(GetAttReportClassAsync, method, data);
   
        yield put(getAttReport( { data: response } ));
        
          
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}


/////// STUDENT STUDY RECORDS
function* watchGetStudyRecords() {
    yield takeEvery(GET_DATA_REQUEST, getstudyrecords);
}

const GetStudyRecordsAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

// Create a generator function
function* getstudyrecords( action ) {

    const { method } = {method:'GET'};
    const data = {url: "student/studentprogram", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey')
                };
    const { history } = action.payload;
    
    try {
        const response = yield call(GetStudyRecordsAsync, method, data);
        yield put(getStudyRecord( { data: response } ));
        
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}

/////// STUDENT STUDY RECORDS DETAIL For BA
function* watchGetStudyRecordsDetailBA() {
    yield takeEvery(GET_DATA_REQUEST, getstudyrecorddetailba);
}

const GetStudyRecordDetailBAAsync = async (method, data) =>
    await requestAPIInHeader(method, data)

function* getstudyrecorddetailba( action ) {

    const { method } = {method:'GET'};
    const data = {url: "student/studentprogramdetail", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey')
                };
    const { history } = action.payload;
    
    try {
        const response = yield call(GetStudyRecordDetailBAAsync, method, data);
        yield put(getStudyRecordDetailBA( { data: response } ));
        
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        history.push('/user/login');
        localStorage.clear();

    }
}

/////// STUDENT STUDY RECORDS DETAIL NOT BA
function* watchGetStudyRecordsDetailNOTBA() {
    yield takeEvery(TYPES.GET_STUDYRECORD_NOTBA, getstudyrecorddetailNOTba);
}

const GetStudyRecordDetailNOTBAAsync = async (method, data) =>
    await requestAPICourseID(method, data)

function* getstudyrecorddetailNOTba( payload ) {

    const { method } = {method:'GET'};
    const courseids = payload['payload']['courseid'];
    const data = {
                  url: "student/studentprogramnotba", 
                  studentid: localStorage.getItem('user_id'), 
                  authkey: localStorage.getItem('authkey'),
                  courseid: courseids
                };
                
   //console.log(payload);
   // const { history } = payload;
    
    try {
        const response = yield call(GetStudyRecordDetailNOTBAAsync, method, data);
        yield put(getStudyRecordDetailNotBA_Success( { data: response, courseid: courseids } ));
        
    }catch(error) { 
        yield put(getDataError(error));
        yield delay(500);
        //history.push('/user/login');
        localStorage.clear();

    }
}
*/

export function* watchRequestscorereview() {
    yield takeEvery(REQUEST_SCORE_REVIEW, requestScoreReview);
}

const RequestScoreReviewAsync = async (method, data) =>
    await requestAPIInHeaderwithBody(method, data)

function* requestScoreReview({ payload }) {
 
    const { method } = {method:'POST'};
    const data = { url: "student/requestbascorereview",
                   studentid: localStorage.getItem('user_id'),
                   authkey: localStorage.getItem('authkey'),
                   body: payload.data
            };

    const { history } = payload;
   
    try {
        const onScorReveiwRequest = yield call(RequestScoreReviewAsync, method, data);
        if (onScorReveiwRequest.data.message == "Successfully Requested") {
            yield put(getRequestSuccess("success"));
        } else {
            yield put(getRequestFail(onScorReveiwRequest.data.message));
        }
    } catch (error) {
        yield put(loginUserError('Please check your connection'));

    }
}

/*
export function* watchRequestnameplage() {
    yield takeEvery(REQUEST_NAMEPLATE, requestNamplate);
}

const RequestNamePlateAsync = async (method, data) =>
    await requestAPIInHeaderwithBody(method, data)

function* requestNamplate({ payload }) {
 
    const { method } = {method:'POST'};
    const data = { url: "student/requestbascorereview",
                   studentid: localStorage.getItem('user_id'),
                   authkey: localStorage.getItem('authkey'),
                   body: payload.data
            };

    const { history } = payload;
   
    try {
        const onScorReveiwRequest = yield call(RequestNamePlateAsync, method, data);
        if (onScorReveiwRequest.data.message == "Successfully Requested") {
            yield put(getRequestSuccess("success"));
        } else {
            yield put(getRequestFail(onScorReveiwRequest.data.message));
        }
    } catch (error) {
        yield put(loginUserError('Please check your connection'));

    }
}

*/


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLoginUserwithGoogle),
        fork(watchLogoutUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchGetStudentProfile),
        fork(watchUpdateStudentProfile),
        fork(watchRequestscorereview),
        // fork(watchGetUpcomingclass),
        // fork(watchGetStudySchedlue),
        // fork(watchGetEnrollment),
        // fork(watchGetStudentClass),
        // fork(watchGetAttendantLog),
        // fork(watchGetAttendantReport),
        // fork(watchGetStudyRecords),
        // fork(watchGetStudyRecordsDetailBA),
        // fork(watchGetStudyRecordsDetailNOTBA),
        
        
    ]);
}